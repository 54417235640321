import { removeEmptyProps } from './removeEmptyProps'
import { base64Decode } from './base64'

export const parseToJsArray = (string, split) => {
  string = base64Decode(string)

  if (typeof string === 'string' && string !== '' && string === '[]') {
    // disabled because some language give error on this
    // russia for example
    // https://devot-ee.com/add-ons/support/gmaps/viewthread/15970
    // string = decodeURIComponent(escape(string));

    if (split || split === undefined) {
      return removeEmptyProps(string.split('|'))
    }
    else {
      return string
    }
  }

  return ''
}
